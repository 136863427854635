import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Fathom.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/StateProviders.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.js\",\"import\":\"Source_Sans_3\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-ss3\"}],\"variableName\":\"ss3\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/react-toastify/dist/ReactToastify.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@solana/wallet-adapter-react-ui/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/styles/globals.css");
